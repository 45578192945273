import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { httpsCallable } from 'firebase/functions';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import {
  Card,
  Stack,
  Container,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Chip,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from '@mui/material';
import { db, functions } from '../utils/firebaseConfig';

export default function SMSMessagePage() {
  const [message, setMessage] = useState('');
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [groups, setGroups] = useState([]);
  const [sendingMethod, setSendingMethod] = useState('groups'); // 'groups', 'all', or 'manual'
  const [manualPhoneNumbers, setManualPhoneNumbers] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [messageHistory, setMessageHistory] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    const fetchGroups = async () => {
      const groupsCollection = collection(db, 'groups');
      const groupsSnapshot = await getDocs(groupsCollection);
      const groupsList = groupsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setGroups(groupsList);
    };

    const fetchMessageHistory = async () => {
      const historyCollection = collection(db, 'smsHistory');
      const historyQuery = query(historyCollection, orderBy('sentAt', 'desc'));
      const historySnapshot = await getDocs(historyQuery);
      const historyList = historySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        sentAt: doc.data().sentAt?.toDate()
      }));
      setMessageHistory(historyList);
    };

    fetchGroups();
    fetchMessageHistory();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const sendSMSMessage = httpsCallable(functions, 'sendSMSMessage');
      
      const payload = {
        message,
        sendingMethod,
        selectedGroups: sendingMethod === 'groups' ? selectedGroups : [],
        manualPhoneNumbers: sendingMethod === 'manual' ? manualPhoneNumbers.split(',').map(num => num.trim()) : []
      };

      await sendSMSMessage(payload);
      alert('Messages sent successfully!');
      setMessage('');
      setSelectedGroups([]);
      setManualPhoneNumbers('');
    } catch (error) {
      console.error('Error sending messages:', error);
      alert('Error sending messages. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Helmet>
        <title> SMS Messages | HelloVIP </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Send SMS Messages
          </Typography>
        </Stack>

        <Card sx={{ p: 3, mb: 4 }}>
          <form onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <FormControl>
                <InputLabel>Sending Method</InputLabel>
                <Select
                  value={sendingMethod}
                  onChange={(e) => setSendingMethod(e.target.value)}
                  label="Sending Method"
                >
                  <MenuItem value="groups">Select Groups</MenuItem>
                  <MenuItem value="all">All Users</MenuItem>
                  <MenuItem value="manual">Manual Phone Numbers</MenuItem>
                </Select>
              </FormControl>

              {sendingMethod === 'groups' && (
                <FormControl>
                  <InputLabel>Select Groups</InputLabel>
                  <Select
                    multiple
                    value={selectedGroups}
                    onChange={(e) => setSelectedGroups(e.target.value)}
                    input={<OutlinedInput label="Select Groups" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((groupId) => (
                          <Chip 
                            key={groupId} 
                            label={groups.find(g => g.id === groupId)?.name || groupId} 
                          />
                        ))}
                      </Box>
                    )}
                  >
                    {groups.map((group) => (
                      <MenuItem key={group.id} value={group.id}>
                        {group.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {sendingMethod === 'manual' && (
                <TextField
                  label="Phone Numbers (comma-separated)"
                  value={manualPhoneNumbers}
                  onChange={(e) => setManualPhoneNumbers(e.target.value)}
                  multiline
                  rows={2}
                  helperText="Enter phone numbers separated by commas"
                />
              )}

              <TextField
                label="Message"
                multiline
                rows={4}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />

              <Button
                type="submit"
                variant="contained"
                size="large"
                disabled={isLoading || !message || (sendingMethod === 'groups' && selectedGroups.length === 0) || (sendingMethod === 'manual' && !manualPhoneNumbers)}
              >
                {isLoading ? 'Sending...' : 'Send Messages'}
              </Button>
            </Stack>
          </form>
        </Card>

        <Card sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ p: 2, pb: 0 }}>
            Message History
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Sent At</TableCell>
                  <TableCell>Message</TableCell>
                  <TableCell>Method</TableCell>
                  <TableCell>Recipients</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Cost</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {messageHistory
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((history) => (
                    <TableRow key={history.id}>
                      <TableCell>
                        {history.sentAt ? format(history.sentAt, 'MMM dd, yyyy HH:mm') : 'N/A'}
                      </TableCell>
                      <TableCell>{history.message}</TableCell>
                      <TableCell>
                        {history.sendingMethod === 'groups' 
                          ? `Groups (${history.selectedGroups.length})`
                          : history.sendingMethod}
                      </TableCell>
                      <TableCell>{history.recipientCount}</TableCell>
                      <TableCell>{history.status}</TableCell>
                      <TableCell>${history.cost?.toFixed(2) || '0.00'}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={messageHistory.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Card>
      </Container>
    </>
  );
} 