// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  // {
  //   title: 'dashboard',
  //   path: '/dashboard/app',
  //   icon: icon('ic_analytics'),
  // },
  {
    title: 'users',
    path: '/dashboard/user',
    icon: icon('ic_user'),
  },
  {
    title: 'events',
    path: '/dashboard/events',
    icon: icon('ic_calendar'),
  },
  {
    title: 'categories',
    path: '/dashboard/categories',
    icon: icon('ic_categories'),
  },
  {
    title: 'groups',
    path: '/dashboard/groups',
    icon: icon('ic_tag'),
  },
  {
    title: 'push notifications',
    path: '/dashboard/push-notifications',
    icon: icon('ic_notification'),
  },
  {
    title: 'SMS Messages',
    path: '/dashboard/sms',
    icon: icon('ic_sms'),
  },
  {
    title: 'Winner reports',
    path: '/dashboard/winner-reports',
    icon: icon('ic_reports'),
  },
  {
    title: 'Previous Events',
    path: '/dashboard/previous-events',
    icon: icon('ic_calendar'),
  },
  // {
  //   title: 'configuration',
  //   path: '/dashboard/configuration',
  //   icon: icon('ic_gear'),
  // },
  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: icon('ic_cart'),
  // },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
