// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB8bsMRihmm-juBuwvNoPIJyIbl3OEe1Jo",
  authDomain: "hellovip-f1a1c.firebaseapp.com",
  projectId: "hellovip-f1a1c",
  storageBucket: "hellovip-f1a1c.appspot.com",
  messagingSenderId: "725092784370",
  appId: "1:725092784370:web:19bbe69d2a5893eddf186b",
  measurementId: "G-Y1D9D5M6JS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const functions = getFunctions(app);
export const db = getFirestore(app);

export default app;